import create from 'zustand';
import ConfigApi from '../api/ConfigApi';

const useStore = create((set) => ({
    multilang: [],

    readAllNotification: async () => {
        const res = await ConfigApi.readAllNotification();
        if (res) {
            return true;
        } else {
            return false;
        }
    },
    getMultilang: async () => {
        const rdata = await ConfigApi.getMultilang();
        if (rdata) {
            set({ multilang: rdata.data.data })
            return rdata.data;
        } else {
            return [];
        }
    }
}));

export default useStore;
