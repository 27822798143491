import Media from 'react-media';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';

import { Root } from '../../../config/config';

import BaseZustand from '../../../zustand/BaseZustand';
import AuthZustand from '../../../zustand/AuthZustand';

import Avatar from '../Header/Avatar';
import Intl from '../Header/Intl';
import Notification from '../Header/Notification';
import Balanace from '../Header/Balance';

import Login from '../../../pages/auth';

import {
    AppBarWrapp,
    MainHeader,
    HeaderBox,
    Logo,
    HeaderMenuBox,
    DehazeWrapp,
    AuthBtn,
    LoginBtn,
    IntlContent,
    BackBtn,
    BackIcon
} from './style';
import ThemeSwitch from '../../../theme/ThemeSwitch';
import { useTheme } from 'styled-components';
import { Theme } from '../../../theme';

const Header = ({ page = '', back = false }) => {
    const theme = useTheme() as Theme;
    const { t } = useTranslation();
    const history = useHistory();
    const { isAuth, logoImage } = AuthZustand((state) => state);
    const { isDesktopSidebar, setAuthModal, setSidebar, sidebar } =
        BaseZustand((state) => state);

    return (
        <AppBarWrapp position="fixed" open={isDesktopSidebar} page={page}>
            <Container maxWidth="xl">
                <MainHeader>
                    <Media
                        queries={{
                            large: '(min-width: 1201px)',
                            small: '(max-width: 1200px)'
                        }}
                    >
                        {(matches) => (
                            <>
                                {matches.large && (
                                    <>
                                        {back ? (
                                            <BackBtn>
                                                <BackIcon
                                                    variant="contained"
                                                    onClick={() =>
                                                        history.push('/')
                                                    }
                                                >
                                                    {t('BACK TO HOME')}
                                                </BackIcon>
                                            </BackBtn>
                                        ) : (
                                            <></>
                                        )}
                                        <HeaderBox>
                                            <Logo
                                                src={`${Root.serverUrl}${logoImage}`}
                                                onClick={() =>
                                                    history.push('/')
                                                }
                                                alt=""
                                            />
                                        </HeaderBox>
                                    </>
                                )}
                                {matches.small && (
                                    <HeaderMenuBox>
                                        <DehazeWrapp
                                            onClick={() => setSidebar(!sidebar)}
                                        />
                                    </HeaderMenuBox>
                                )}
                            </>
                        )}
                    </Media>
                    <AuthBtn>
                        {!isAuth ? (
                            <LoginBtn
                                sx={{ color: theme.text }}
                                variant="contained"
                                onClick={() =>
                                    setAuthModal(
                                        { login: true, register: false },
                                        history
                                    )
                                }
                            >
                                {t('LOGIN')}
                            </LoginBtn>
                        ) : (
                            <>
                                {history.location.pathname !== '/sports' && (
                                    <Notification />
                                )}
                                <Balanace />
                                <Avatar />
                            </>
                        )}
                        <IntlContent>
                            <Intl />
                            <ThemeSwitch />
                        </IntlContent>
                    </AuthBtn>
                </MainHeader>
            </Container>
            <Login />
        </AppBarWrapp>
    );
};

export default Header;
