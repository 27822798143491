import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthZustand from '../../zustand/AuthZustand';
import ConifgZustand from '../../zustand/ConifgZustand';
import { Root } from '../../config/config';

const AuthProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const { sessionCheck } = AuthZustand((state) => state);
    const { getMultilang } = ConifgZustand((state) => state);
    const [flag, setFlag] = useState(false);

    const init = async () => {
        await sessionCheck();
        const langData = await getMultilang();
        for (let i = 0; i < langData.data.length; i++) {
            const oneItem = langData.data[i]
            const lang = oneItem.fileName.replace(`${Root.site}-`, '').replace('.json', '')
            if (lang) {
                i18n.addResourceBundle(
                    lang,
                    'translation',
                    oneItem.content
                );
            }
        }
        setFlag(true);
    };
    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    return <>{flag ? children : ''}</>;
};

export default AuthProvider;
