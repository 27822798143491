import APIAbstract from './APIAbstract';
import { Root } from '../config/config';

class ProfileApi extends APIAbstract {
    playerProfileUpdate(data) {
        return this.post('/player/profile/update', data);
    }
    changepassword(data) {
        return this.post('/player/profile/changepassword', data);
    }
    getSessionHistory(data) {
        return this.post('/player/profile/getSessionHistory', data);
    }
    updateLanguage() {
        // return this.post('/api/user/updateLanguage', data);
        return null
    }
}

const ProfileClass = new ProfileApi(`${Root.serverUrl}`);

export default ProfileClass;
