import React, { Suspense, lazy, useEffect } from 'react';
import {
    Switch,
    Route,
    BrowserRouter as Router,
    Redirect
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MainLayout from './layout/layoutList/main';
import GameLayout from './layout/layoutList/game';

import ProfileLayout from './layout/layoutList/profile';
import AuthZustand from './zustand/AuthZustand';
import { RouterProvider } from './context/RouterContext';

const Dashboard = lazy(() => import('./pages/dashboard'));
const Games = lazy(() => import('./pages/game'));
const ProfilePage = lazy(() => import('./pages/user/profile'));
const BalanceHistory = lazy(() => import('./pages/user/history'));
const MultiLang = lazy(() => import('./pages/multi'));

const Authmiddleware = (props: any) => {
    const { component: Component, layout, isLogin, ...rest } = props;
    const { isAuth } = AuthZustand((state) => state);

    return (
        <Route
            {...rest}
            render={(props: any) => {
                if (isLogin) {
                    if (!isAuth) {
                        return <Redirect to="/" />;
                    }
                }

                if (layout === 'MainLayout') {
                    return (
                        <MainLayout>
                            <Suspense fallback={<></>}>
                                <Component {...props} />
                            </Suspense>
                        </MainLayout>
                    );
                } else if (layout === 'GameLayout') {
                    return (
                        <GameLayout>
                            <Suspense fallback={<></>}>
                                <Component {...props} />
                            </Suspense>
                        </GameLayout>
                    );
                } else {
                    return (
                        <ProfileLayout>
                            <Suspense fallback={<></>}>
                                <Component {...props} />
                            </Suspense>
                        </ProfileLayout>
                    );
                }
            }}
        />
    );
};

Authmiddleware.propTypes = {
    component: PropTypes.any,
    layout: PropTypes.string,
    path: PropTypes.string,
    exact: PropTypes.bool,
    isLogin: PropTypes.bool
};

const AppRouter: React.FC = () => {
    const { i18n } = useTranslation();
    const { languageData } = AuthZustand((state) => state);

    useEffect(() => {
        if (languageData.length) {
            let lang = localStorage.getItem('player-langauge');
            if(!lang) {
                lang = 'en';
            }

            const flag = languageData.findIndex(
                (item) => item.language === lang
            );
            if (flag < 0) {
                i18n.changeLanguage('en');
            } else {
                i18n.changeLanguage(lang);
            }
        }
        // eslint-disable-next-line
    }, [languageData]);

    return (
        <Router>
            <RouterProvider>
                <Switch>
                    <Authmiddleware
                        path="/"
                        exact
                        component={Dashboard}
                        layout="MainLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/home"
                        exact
                        component={Dashboard}
                        layout="MainLayout"
                        isLogin={false}
                    />

                    <Authmiddleware
                        path="/games"
                        exact
                        component={Games}
                        layout="GameLayout"
                        isLogin={false}
                    />
                    <Authmiddleware
                        path="/profile"
                        exact
                        component={ProfilePage}
                        layout="ProfileLayout"
                        isLogin={true}
                    />
                    <Authmiddleware
                        path="/balance-history"
                        exact
                        component={BalanceHistory}
                        layout="ProfileLayout"
                        isLogin={true}
                    />
                    <Authmiddleware
                        path="/multilang"
                        exact
                        component={MultiLang}
                        layout="MainLayout"
                        isLogin={true}
                    />
                    <Authmiddleware
                        component={Dashboard}
                        layout="MainLayout"
                        isLogin={false}
                    />
                </Switch>
            </RouterProvider>
        </Router>
    );
};

export default AppRouter;
