import { useState, Fragment, useCallback } from 'react';
import { useHistory } from 'react-router';
import Popover from '@mui/material/Popover';
import { useTranslation } from 'react-i18next';

import AuthZustand from '../../../../zustand/AuthZustand';
import BaseZustand from '../../../../zustand/BaseZustand';

import { UserDropDownList } from './variable';
import { AvatarBox, ListItem } from './style';
import { useTheme } from 'styled-components';
import { Theme } from '../../../../theme';
import { Root } from '../../../../config/config';

const AvatarComponent = () => {
    const theme = useTheme() as Theme;
    const { t } = useTranslation();

    const history = useHistory();
    const { user, userLogout } = AuthZustand(
        (state) => state
    );
    const { setSidebar } = BaseZustand((state) => state);

    const [dropTag, setDropTag] = useState(null);
    const open = Boolean(dropTag);
    const id = open ? 'simple-popover' : undefined;

    const changePage = useCallback(async (url) => {
        history.push(url)
        setDropTag(null);
        setSidebar(false);
        // eslint-disable-next-line
    }, []);

    const userLogoutFunc = useCallback(() => {
        userLogout();
        setDropTag(null);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <AvatarBox
                aria-describedby={id}
                className="avatar"
                onClick={(e) => setDropTag(e.currentTarget)}
                sx={{
                    background: theme.background.primary,
                    color: theme.text
                }}
            >
                <img src={`${Root.serverUrl}/image/avatar/avatar.png`} alt="" />
            </AvatarBox>
            <Popover
                id={id}
                open={open}
                anchorEl={dropTag}
                onClose={() => setDropTag(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                sx={{ backgroundColor: 'transparent' }}
            >
                {UserDropDownList.map((item, key) => (
                    <Fragment key={key}> 
                        {item.name === 'Logout' ? (
                            <ListItem
                                onClick={() => userLogoutFunc()}
                                sx={{
                                    background: theme.background.primary,
                                    color: theme.text
                                }}
                            >
                                {t('Logout')}
                            </ListItem>
                        ) : (
                            <ListItem
                                onClick={() => changePage(item.url)}
                                sx={{
                                    background:
                                        theme.background.primary,
                                    color: theme.text
                                }}
                            >
                                {t(item.name)}&nbsp;
                                {key === 0 && `(${user.username})`}
                            </ListItem>
                        )}
                    </Fragment>
                ))}
            </Popover>
        </>
    );
};

export default AvatarComponent;
