import React, { useContext, useState } from 'react';
import { IconButton, Radio, MenuItem, Menu } from '@mui/material';
import PaletteIcon from '@mui/icons-material/Palette';
import { SwitchWrapper } from './style';
import { ThemeContext } from '../../context/ThemeContext';
import { Theme, themeList } from '../index';
import { useTheme } from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

const ThemeSwitch = () => {
    const { t } = useTranslation();

    const theme = useTheme() as Theme;
    const { setCustomMainTheme, currentTheme } = useContext(ThemeContext);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleThemeSelect = (selectedTheme) => {
        setCustomMainTheme(selectedTheme);
        handleMenuClose();
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <SwitchWrapper>
                <Tooltip title={t('Change color theme')}>
                    <IconButton onClick={handleMenuOpen}>
                        <PaletteIcon sx={{ color: theme.text }} />
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                        style: {
                            background: theme.background.primary
                        }
                    }}
                >
                    {themeList.map((themeOption) => (
                        <MenuItem
                            key={themeOption.id}
                            onClick={() => handleThemeSelect(themeOption.theme)}
                            sx={{ color: theme.text }}
                        >
                            <Radio
                                color="default"
                                checked={
                                    currentTheme.mode === themeOption.theme.mode
                                }
                                sx={{ color: theme.text }}
                            />
                            {t(themeOption.name)}
                        </MenuItem>
                    ))}
                </Menu>
            </SwitchWrapper>
        </>
    );
};

export default ThemeSwitch;
