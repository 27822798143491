import { useTranslation } from 'react-i18next';
import Media from 'react-media';
import Container from '@mui/material/Container';

import AuthZustand from '../../../../zustand/AuthZustand';

import { Root } from '../../../../config/config';

import {
    Footer,
    FooterContent,
    FooterLink,
    Logo,
    FooterText,
    FooterRule,
    FooterRule1
} from './style';

const MainFooterPage = ({ show = true }) => {
    const { t } = useTranslation();
    const { logoImage } = AuthZustand((state) => state);

    return (
        <Footer show={String(show)}>
            <Container maxWidth="xl">
                <FooterContent container spacing={1}>
                    <Media
                        queries={{
                            large: '(min-width: 769px)',
                            small: '(max-width: 768px)'
                        }}
                    >
                        {(matches) => (
                            <>
                                {matches.small && (
                                    <FooterLink>
                                        <Logo
                                            src={`${Root.serverUrl}${logoImage}`}
                                            alt=""
                                        />
                                    </FooterLink>
                                )}
                            </>
                        )}
                    </Media>
                </FooterContent>
                <FooterContent>
                    <FooterText>
                        {t('footer1')}
                    </FooterText>
                </FooterContent>
                <FooterContent container>
                    <FooterRule1>
                        <img
                            src={`${Root.serverUrl}/image/site/rule/18plus.svg`}
                            alt=""
                        />
                    </FooterRule1>
                    <FooterRule>
                        <img
                            src={`${Root.serverUrl}/image/site/rule/gamecare.png`}
                            alt=""
                        />
                    </FooterRule>
                    <FooterRule>
                        <img
                            src={`${Root.serverUrl}/image/site/rule/111.png`}
                            alt=""
                        />
                    </FooterRule>
                </FooterContent>

                <FooterContent container>
                    <FooterText>
                        @2020 - {new Date().getFullYear()}&nbsp;{Root.site}
                        .&nbsp;{t('All rights reserved')}.
                    </FooterText>
                </FooterContent>
            </Container>
        </Footer>
    );
};

export default MainFooterPage;
